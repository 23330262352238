import React, { useState } from 'react';
import { ViewMode } from 'gantt-task-react';
import { Button, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { useGantt } from 'hooks/useGantt';
import { ListIcon } from 'components/icons/List';
import { useNavigate, useParams } from 'react-router-dom';
import { ChainIcon } from 'components/icons/Chain';
import { TrustModal } from 'components/auth/TrustModal';
import { useAuth } from 'hooks/useAuth';

export const ViewSwitcher = () => {
  const {
    viewMode,
    setViewMode,
    editableTask,
    cancelEditTask,
    saveTask,
    form,
  } = useGantt();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [trustProjectId, setTrustProjectId] = useState<null | number>(null);
  const { user } = useAuth();
  const isAuthUser = Boolean(!user?.isGuest);

  return (
    <>
      <TrustModal
        trustProjectId={trustProjectId}
        setTrustProjectId={setTrustProjectId}
      />
      <div
        style={{ display: 'flex', flexDirection: 'row', gap: 10, padding: 10 }}
      >
        {isAuthUser && process.env.REACT_APP_GANTT && (
          <Button
            variant="outline-success"
            onClick={() => setTrustProjectId(Number(projectId) ?? null)}
            title="Поделиться проектом"
          >
            <ChainIcon width={20} height={20} />
          </Button>
        )}
        {isAuthUser && (
          <Button
            variant="outline-success"
            onClick={() => navigate('/projects')}
          >
            <ListIcon /> Проекты
          </Button>
        )}
        <ButtonGroup style={{ marginLeft: 32 }}>
          <ToggleButton
            type="radio"
            variant="outline-success"
            value="Hour"
            id="Hour"
            checked={viewMode === ViewMode.Hour}
            onChange={() => setViewMode(ViewMode.Hour)}
          >
            Час
          </ToggleButton>
          <ToggleButton
            type="radio"
            variant="outline-success"
            value="QuarterDay"
            id="QuarterDay"
            checked={viewMode === ViewMode.QuarterDay}
            onChange={() => setViewMode(ViewMode.QuarterDay)}
          >
            6 часов
          </ToggleButton>
          <ToggleButton
            type="radio"
            variant="outline-success"
            value="HalfDay"
            id="HalfDay"
            checked={viewMode === ViewMode.HalfDay}
            onChange={() => setViewMode(ViewMode.HalfDay)}
          >
            Полдня
          </ToggleButton>
          <ToggleButton
            type="radio"
            variant="outline-success"
            value="Day"
            id="Day"
            checked={viewMode === ViewMode.Day}
            onChange={() => setViewMode(ViewMode.Day)}
          >
            День
          </ToggleButton>
          <ToggleButton
            type="radio"
            variant="outline-success"
            value="Week"
            id="Week"
            checked={viewMode === ViewMode.Week}
            onChange={() => setViewMode(ViewMode.Week)}
          >
            Неделя
          </ToggleButton>
          <ToggleButton
            type="radio"
            variant="outline-success"
            value="Month"
            id="Month"
            checked={viewMode === ViewMode.Month}
            onChange={() => setViewMode(ViewMode.Month)}
          >
            Месяц
          </ToggleButton>
        </ButtonGroup>
      </div>
    </>
  );
};
